import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class BlogCategoryService  {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/category';
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    all() {
        let url = `${this.#api}/get/all`
        return apiService.get(url)
    }

    sort(data=[]){
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getParent(){
        let url = `${this.#api}/parent/all`;
        return apiService.get(url)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    update(id,data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url,data)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
}
