<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{ blogId ? 'Update' : 'Add' }} Blog
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \
                  Blog
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="blog.name"
                    :error="$v.blog.name.$error"
                    outlined
                    dense
                >
                  <template v-slot:label>
                    Name <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.blog.name.$error">Name is required</span>
                <span class="text-danger" v-if="errors.name">* {{ errors.name[0] }}</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="blog.publish_date"
                        :error="$v.blog.publish_date.$error"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                    >
                      <template v-slot:label>
                        Publish Date <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="blog.publish_date"
                      outlined
                      dense
                      no-title
                      @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
                <span class="text-danger" v-if="$v.blog.publish_date.$error">Publish Date is required</span>
                <span class="text-danger" v-if="errors.publish_date">* {{ errors.publish_date[0] }}</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                    label="Category *"
                    v-model="blog.category_id"
                    :items="blog_categories"
                    item-text="name"
                    item-value="id"
                    :error="$v.blog.category_id.$error"
                    outlined
                    dense
                >
                  <template v-slot:label>
                    Category <span class="text-danger">*</span>
                  </template>
                </v-select>
                <span class="text-danger" v-if="$v.blog.category_id.$error">Category is required</span>
                <span class="text-danger" v-if="errors.category_id">* {{ errors.category_id[0] }}</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Keywords"
                    v-model="blog.keywords"
                    outlined
                    dense
                >

                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Seo Title"
                    v-model="blog.seo_title"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Seo Keyword"
                    v-model="blog.seo_keyword"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" v-bind:md="imgurl != null? 6 : 12">
                <v-file-input
                    label="File"
                    v-model="blog.file"
                    @change="previewImage"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    outlined
                    dense
                >
                </v-file-input>
              </v-col>

              <v-col cols="12" md="6" v-if="imgurl != null">
                <div class="text-center">
                  <v-img
                      :src="imgurl"
                      :lazy-src="imgurl"
                      max-height="150"
                      max-width="150"
                      class="img-thumbnail"
                  ></v-img>
                </div>
              </v-col>

              <v-col cols="12" md="6">
                Description <span class="text-danger">*</span>
                <ckeditor
                    :config="getEditorConfig()"
                    v-model="blog.description"
                >
                </ckeditor>
                <span class="text-danger" v-if="$v.blog.description.$error">Description Field is required</span>
                <span class="text-danger" v-if="errors.description">* {{ errors.description[0] }}</span>
              </v-col>

              <v-col cols="12" md="6">
                Seo Description
                <ckeditor
                    :config="getEditorConfig()"
                    v-model="blog.seo_description"
                >
                </ckeditor>
              </v-col>

              <v-col cols="12" md="6">
                Social Share Description
                <ckeditor
                    :config="getEditorConfig()"
                    v-model="blog.social_share_description"
                >
                </ckeditor>
              </v-col>

              <v-col cols="12" md="6">
                Status
                <v-switch
                    v-model="blog.is_active"
                    :label="blog.is_active ? 'Active' : 'Inactive'"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12 text-right">
                <v-btn
                    :loading="loading"
                    @click="createOrUpdate"
                    class="btn btn-primary text-white"
                    v-if="checkIsAccessible('blog', 'create') || checkIsAccessible('blog', 'edit')"
                >Save
                </v-btn>
                <v-btn
                    @click="cancel"
                    class="ml-4 btn btn-standard text-white"
                >Cancel
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import BlogService from "@/services/cms/blog/BlogService";
import BlogCategoryService from "@/services/cms/blog/category/BlogCategoryService";
import {required} from "vuelidate/lib/validators";

const blog = new BlogService();
const blogCategory = new BlogCategoryService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      menu: false,
      loading: false,
      blog_categories: [],
      errors: [],
      blogId: '',
      imgurl: null,
      blog: {
        name: '',
        file: null,
        description: '',
        keywords: '',
        publish_date: '',
        seo_title: '',
        seo_keyword: '',
        seo_description: '',
        social_share_image: '',
        social_share_description: '',
        category_id: '',
        is_active: true,
      },
      editorConfig: {
        versionCheck: false,
        toolbarGroups: [
          {name: 'document', groups: ['mode', 'document', 'doctools']},
          {name: 'clipboard', groups: ['clipboard', 'undo']},
          {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
          {name: 'forms', groups: ['forms']},
          {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
          '/',
          {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
          {name: 'links', groups: ['links']},
          {name: 'insert', groups: ['insert']},
          '/',
          {name: 'styles', groups: ['styles']},
          {name: 'colors', groups: ['colors']},
          {name: 'tools', groups: ['tools']},
          {name: 'others', groups: ['others']},
          {name: 'about', groups: ['about']}
        ],
        extraPlugins: ['font', 'colorbutton'],
        colorButton_enableMore: true,
      },
      editorConfig1: null,
      editorConfig2: null,
    }
  },
  validations: {
    blog: {
      name: {required},
      publish_date: {required},
      description: {required},
      category_id: {required},
      is_active: {required}
    }
  },
  mounted() {
    this.blogId = this.$route.params.blogId;
    this.editorConfig1 = this.editorConfig
    this.editorConfig2 = this.editorConfig
    this.blogDetail();
    this.getAllParent();
  },
  methods: {
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.blog) {
        if (key === "file" && this.blog[key] != null && this.blog[key] != undefined) {
          formData.append('file', this.blog[key]);
        } else {
          if (this.blog[key]) {
            formData.append(key, this.blog[key]);
          }
        }
      }
      return formData;
    },
    blogDetail() {
      if (this.blogId != null || this.blogId != undefined) {
        blog
            .show(this.blogId)
            .then(response => {
              this.blog = response.data.blog;
              if (this.blog.image_path != null) {
                this.imgurl = this.blog.image_path.thumb;
              }
            }).catch(err => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },
    previewImage() {
      if (this.blog.file != null) {
        this.imgurl = URL.createObjectURL(this.blog.file)
      } else {
        this.imgurl = null;
      }
    },
    getAllParent() {
      blogCategory
          .getParent()
          .then(response => {
            this.blog_categories = response.data.data;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (this.blogId == null || this.blogId == undefined) this._create();
        else this.update();
      }
    },
    cancel() {
      this.$tabs.close();
    },
    _create: function () {
      let formData = this.convertToFormData();
      blog
          .create(formData)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Blog created successfully");
            this.resetForm();
            this.$router.push({
              name: "blog",
            });
            this.$tabs.close();

          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      let formData = this.convertToFormData();
      blog
          .update(this.blog.id, formData)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Blog updated successfully");
            this.resetForm();

            this.$router.push({
              name: "blog",
            });
            this.$tabs.close();
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm() {
      this.$v.$reset();
      this.blog = {
        name: '',
        file: null,
        description: '',
        keywords: '',
        publish_date: '',
        seo_title: '',
        seo_keyword: '',
        seo_description: '',
        social_share_image: '',
        social_share_description: '',
        category_id: '',
        is_active: true,
      }
    },
    getEditorConfig(){
      return {
          toolbarGroups: [
            {name: 'document', groups: ['mode', 'document', 'doctools']},
            {name: 'clipboard', groups: ['clipboard', 'undo']},
            {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
            {name: 'forms', groups: ['forms']},
            {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
            '/',
            {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
            {name: 'links', groups: ['links']},
            {name: 'insert', groups: ['insert']},
            '/',
            {name: 'styles', groups: ['styles']},
            {name: 'colors', groups: ['colors']},
            {name: 'tools', groups: ['tools']},
            {name: 'others', groups: ['others']},
            {name: 'about', groups: ['about']}
          ],
          // toolbar: [
          //     ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'SpellChecker', 'Scayt','RemoveFormat', 'About', ]
          // ],
          scayt_sLang: 'en_GB',
          scayt_autoStartup: true,
          allowedContent: true,
          scayt_disableOptionsStorage: 'lang',
      }
    },
  }
}
</script>
